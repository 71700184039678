import React, { Fragment, useCallback, useEffect, useState } from "react";
import "./App.css";
import { Dialog, Transition } from "@headlessui/react";
import classnames from "classnames";
import copy from "copy-to-clipboard";

type Day = {
  dayNo: number;
  imageUrl: string;
  isAvailable: boolean;
  wasOpened: boolean;
  content: string;
  downloadLink: string;
  youTubeEmbedURL?: string;
  salesCode?: string;
};

type ApiDay = Omit<Day, "imageUrl">;

type ContentProps = {
  daysData: Day[];
  onTileClick: (day: Day) => void;
};

type ModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  selectedDay?: Day;
};

const ContentBox = ({ children }: { children: React.ReactNode }) => (
  <div className="w-full sm:w-5/6 md:w-8/12 lg:w-9/12 xl:w-7/12 2xl:w-1/2 space-y-4">
    {children}
  </div>
);

const Content = ({ daysData, onTileClick }: ContentProps) => {
  return (
    <>
      <ContentBox>
        <p className="text-lg text-white text-shadow">
          Cześć! Dziękuję Ci bardzo za zakup pierwszego (i mam nadzieję, że nie
          ostatniego) cyfrowego kalendarza adwentowego Tosiakowa! Na tej stronie
          widzisz 24 okienka. Są zamknięte do końca listopada. Ale spokojnie!
          Już pierwszego grudnia możesz tu wrócić i otworzyć pierwsze okienko :)
        </p>
        <p className="text-lg text-white text-shadow">
          Od razu mówię - kolejne okienka będą zamknięte, żeby nie psuć Ci
          zabawy. Zachęcam do codziennego otwierania kalendarza :) Jeżeli
          któregoś dnia nie zdążysz otworzyć okienka, nic się nie stanie. Po
          prostu kolejnego dnia poza aktualnym okienkiem otworzysz również te
          brakujące.
        </p>
        <p className="text-lg text-white text-shadow">
          Arkusze przygotowane są w rozmiarze A4, ale nic nie stoi na
          przeszkodzie, żeby je drukować w mniejszym lub większym formacie. W
          ten sposób możesz sobie je dopasować do swoich potrzeb.
        </p>
        <p className="text-lg text-white text-shadow">
          Jeżeli masz do mnie jakieś pytania - pisz na maila:{" "}
          <a href="mailto:kontakt@tosiakowo.pl">kontakt@tosiakowo.pl</a>
        </p>
        <p className="text-lg text-white text-shadow">
          UWAGA - Dostęp do tego kalendarza masz do końca września 2024 roku.
          <br />
          Pamiętaj, żeby do tego czasu pobrać wszystkie arkusze.
        </p>
      </ContentBox>

      <div className="w-full gap-5 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6">
        {daysData.map((day) => (
          <button
            key={day.dayNo}
            className={classnames(
              "inline-flex bg-white border-4 border-white overflow-hidden",
              "bg-no-repeat bg-left-top bg-[length:100%]",
              "aspect-square shadow-xl rounded-lg",
              "focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2",
              "group",
              {
                "transition ease-in-out md:hover:-translate-y-1 md:hover:scale-125 md:hover:rotate-3 duration-300":
                  day.isAvailable,
              }
            )}
            style={{ backgroundImage: `url(${day.imageUrl})` }}
            onClick={() => onTileClick(day)}
            aria-label={`Otwórz okienko z ${day.dayNo}. grudnia`}
          >
            {!day.isAvailable && (
              <span
                className="w-full h-full bg-[rgba(255,255,255,.5)]"
                aria-hidden="true"
              >
                <span
                  className="block w-full h-full bg-no-repeat bg-left-top bg-[length:100%]"
                  style={{ backgroundImage: `url(/ribbon.png)` }}
                ></span>
              </span>
            )}
            {day.wasOpened && (
              <span
                className="transition duration-300 w-full h-full flex items-center justify-center bg-[rgba(0,0,0,.5)] md:group-hover:bg-transparent"
                aria-hidden="true"
              >
                <span className="inline-flex text-white text-shadow-doors text-3xl md:text-2xl -rotate-6">
                  Już otwierane!
                </span>
              </span>
            )}
          </button>
        ))}
      </div>
    </>
  );
};

const CopyToClipboardIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
    />
  </svg>
);

const CopyToClipboardCheckedIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    strokeWidth={1.5}
    stroke="currentColor"
    className="w-6 h-6"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75"
    />
  </svg>
);

const Modal = ({ isModalOpen, setIsModalOpen, selectedDay }: ModalProps) => {
  const [isCopied, setIsCopied] = useState<boolean>(false);

  const handleCopyCodeToClipboard = useCallback(() => {
    if (!selectedDay?.salesCode) {
      return;
    }

    copy(selectedDay.salesCode);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }, [selectedDay?.salesCode]);

  return (
    <Transition appear show={isModalOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-2 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-75"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-75"
            >
              <Dialog.Panel
                className="relative w-full max-w-md md:max-w-lg transform overflow-hidden rounded-xl p-6 text-left align-middle shadow-xl transition-all border-8 border-[#bc6d3c] bg-bottom bg-no-repeat bg-[length:100%] bg-[#9b592f] flex flex-col gap-12 items-center"
                style={{ backgroundImage: "url(/snowflakes-bu.png)" }}
              >
                <div className="absolute inset-0 bg-gradient-radial-modal"></div>
                <Dialog.Title
                  as="h3"
                  className="relative text-center text-5xl sm:text-7xl text-white text-shadow"
                >
                  {selectedDay?.isAvailable
                    ? `${selectedDay?.dayNo}. grudnia`
                    : "Musisz poczekać! :)"}
                </Dialog.Title>
                <div className="relative md:w-5/6 flex flex-col gap-6">
                  <p className="text-lg text-white text-shadow leading-snug">
                    {selectedDay?.isAvailable
                      ? selectedDay?.content
                      : `To okienko będzie dostępne dopiero ${selectedDay?.dayNo}.\u00A0grudnia!`}
                  </p>
                  {selectedDay?.isAvailable && !!selectedDay?.salesCode && (
                    <div className="flex flex-col gap-2">
                      <p className="text-lg text-white text-shadow leading-snug text-center">
                        Twój unikalny kod zniżkowy do wykorzystania w sklepie
                        tosiakowo.pl:
                      </p>
                      <p className="flex items-center justify-center text-2xl text-white text-shadow leading-snug text-center gap-4">
                        <span
                          className="font-bold"
                          style={{ fontFamily: "Courier" }}
                        >
                          {selectedDay?.salesCode}
                        </span>
                        <button
                          className={classnames(
                            "w-fit inline-flex justify-center rounded-md px-3 py-2 text-md focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2 shadow-lg",
                            "transition ease-in-out md:hover:scale-125 duration-300 hover:shadow-xl",
                            {
                              "bg-white text-[#9b592f]": !isCopied,
                              "bg-[#0c0] text-white": isCopied,
                            }
                          )}
                          onClick={() => handleCopyCodeToClipboard()}
                          aria-label="Skopiuj kod zniżkowy do schowka"
                        >
                          {isCopied ? (
                            <CopyToClipboardCheckedIcon aria-hidden="true" />
                          ) : (
                            <CopyToClipboardIcon aria-hidden="true" />
                          )}
                        </button>
                      </p>
                      <p className="text-sm text-white text-shadow leading-snug text-center">
                        Możesz go użyć tylko do jednego zamówienia.
                        <br />
                        Aby zrealizować kod, podaj go w koszyku.
                        <br />
                        Kod ważny jest do końca lutego 2024 roku.
                      </p>
                    </div>
                  )}
                </div>
                {selectedDay?.isAvailable && !!selectedDay?.youTubeEmbedURL && (
                  <div className="relative w-full flex justify-center items-center">
                    <iframe
                      width="560"
                      height="252"
                      src={`${selectedDay.youTubeEmbedURL}&disablekb=1`}
                      title="YouTube video player"
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    ></iframe>
                  </div>
                )}
                {selectedDay?.isAvailable && (
                  <a
                    className="
                        relative w-fit inline-flex justify-center rounded-md bg-white px-8 py-4 text-2xl text-[#9b592f] focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2 shadow-lg
                        transition ease-in-out md:hover:scale-125 duration-300 hover:shadow-xl
                      "
                    href={selectedDay?.downloadLink}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Pobierz grafikę
                  </a>
                )}
                <button
                  type="button"
                  className="
                    relative w-fit inline-flex justify-center rounded-md bg-white px-3 py-2 text-md text-[#9b592f] focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2 shadow-lg
                    transition ease-in-out md:hover:scale-125 duration-300 hover:shadow-xl
                    "
                  onClick={() => setIsModalOpen(false)}
                >
                  Zamknij okienko
                </button>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Day>();
  const [userHash, setUserHash] = useState<string>();
  const [daysData, setDaysData] = useState<Day[]>([]);
  const [fetchError, setFetchError] = useState<Error>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const setLastFetched = useCallback((timestamp: string) => {
    localStorage.setItem("lastFetched", timestamp);
  }, []);

  const getLastFetched = useCallback((): number | undefined => {
    const lastFetchedString = localStorage.getItem("lastFetched");
    return lastFetchedString ? parseInt(lastFetchedString) : undefined;
  }, []);

  const fetchData = useCallback(
    (signal: AbortSignal, userHash: string) => {
      setIsLoading(true);
      setFetchError(undefined);
      fetch(
        `https://kalendarz.tosiakowo.pl/api.php?action=read&hash=${userHash}`,
        { signal }
      )
        .then(async (response) => {
          const result: ApiDay[] = await response.json();
          setDaysData(
            result.map((apiDay) => ({
              ...apiDay,
              imageUrl: `/${apiDay.dayNo.toString().padStart(2, "0")}.png`,
            }))
          );
          setIsLoading(false);
          setFetchError(undefined);
          setLastFetched(new Date().getTime().toString());
        })
        .catch((error) => {
          setIsLoading(false);
          setFetchError(error);
        });
    },
    [setLastFetched]
  );

  const onWindowFocus = useCallback(() => {
    const lastFetched = getLastFetched();
    if (!lastFetched || !userHash) {
      return;
    }

    const now = new Date().getTime();

    // Fetch data if last fetch was more than 15 minutes ago
    if (now > lastFetched + 1000 * 60 * 15) {
      const abortController = new AbortController();
      const signal = abortController.signal;

      fetchData(signal, userHash);
    }
  }, [fetchData, getLastFetched, userHash]);

  useEffect(() => {
    window.addEventListener("focus", onWindowFocus);

    return () => {
      window.removeEventListener("focus", onWindowFocus);
    };
  }, [onWindowFocus]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const newUserHash = params.get("userHash");
    const storedUserHash = localStorage.getItem("userHash");
    const userHash = newUserHash || storedUserHash;

    if (userHash) {
      if (newUserHash) {
        localStorage.setItem("userHash", newUserHash);
      }
      setUserHash(userHash);
    }
  }, []);

  useEffect(() => {
    if (!userHash) {
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;

    fetchData(signal, userHash);

    return () => {
      abortController.abort();
    };
  }, [fetchData, userHash]);

  const isAuthorized = !!userHash && daysData.length > 0;

  const setDayOpened = useCallback(
    (day: Day) => {
      if (!day.isAvailable) {
        return;
      }

      const abortController = new AbortController();
      const signal = abortController.signal;

      // Optimistically set day as opened
      setDaysData((prevDaysData) =>
        prevDaysData.map((d) => ({
          ...d,
          wasOpened: d.dayNo === day.dayNo ? true : d.wasOpened,
        }))
      );

      const revertDayOpened = () => {
        // Revert wasOpened to `false`
        setDaysData((prevDaysData) =>
          prevDaysData.map((d) => ({
            ...d,
            wasOpened: d.dayNo === day.dayNo ? false : d.wasOpened,
          }))
        );
      };

      fetch(
        `https://kalendarz.tosiakowo.pl/api.php?action=dayOpened&hash=${userHash}`,
        {
          body: JSON.stringify({ dayNo: day.dayNo }),
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          signal,
        }
      )
        .then((response) => {
          if (response.status >= 400) {
            revertDayOpened();
          }
        })
        .catch(() => {
          revertDayOpened();
        });
    },
    [userHash]
  );

  const onTileClick = useCallback(
    (day: Day) => {
      setSelectedDay(day);
      setIsModalOpen(true);
      setDayOpened(day);
    },
    [setDayOpened]
  );

  return (
    <>
      <div className="w-full bg-[#b70805] relative min-h-[100vh]">
        <div className="absolute inset-0 w-full h-[50vh] bg-gradient-to-b from-[rgba(241,8,17,.7)] to-[rgba(183,8,4,.7)]"></div>
        <div
          className="
          relative
          flex flex-col
          gap-10 px-5 py-10
          sm:px-10 sm:py-12
          lg:gap-20 lg:p-20
          items-center
          bg-repeat-x
          bg-top
          min-h-[100vh]
        "
          style={{ backgroundImage: "url(/snowflakes-td.png)" }}
        >
          <div className="flex justify-center items-center">
            <a
              className="inline-flex px-10 py-4 bg-white rounded-xl shadow-xl focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
              href="https://tosiakowo.pl"
              target="_blank"
              rel="noreferrer"
              title="Tosiakowo - strona główna"
            >
              <img
                src="https://tosiakowo.pl/wp-content/uploads/2016/06/tosiakowo-logo.png"
                alt="Tosiakowo"
                srcSet="https://tosiakowo.pl/wp-content/uploads/2016/06/tosiakowo-logo.png ,https://tosiakowo.pl/wp-content/uploads/2016/06/tosiakowo-logo@2x.png 2x"
                className="w-[180px] h-[92px] sm:w-[280px] sm:h-[143px]"
              />
            </a>
          </div>

          {!isAuthorized && !isLoading && !fetchError && (
            <ContentBox>
              <p className="text-lg text-white text-shadow">
                Niestety nie masz dostępu do kalendarza adwentowego :(
              </p>
              <p className="text-lg text-white text-shadow">
                Jeśli kupiłaś już dostęp, to użyj linka z maila, który dostałaś
                po zakupie :)
              </p>
              <p className="text-lg text-white text-shadow">
                Jeśli jeszcze nie kupiłaś dostępu, to możesz to zrobić tutaj:
              </p>
              <div className="flex justify-center">
                <a
                  href="https://tosiakowo.pl/produkt/tosiakowy-kalendarz-adwentowy-digi-do-druku/"
                  target="_blank"
                  rel="noreferrer"
                  className="
                        relative w-fit inline-flex justify-center rounded-md bg-white px-8 py-4 text-2xl text-red-700 focus:outline-none focus-visible:ring-8 focus-visible:ring-blue-500 focus-visible:ring-offset-2 shadow-lg
                        transition ease-in-out md:hover:scale-125 duration-300 hover:shadow-xl
                      "
                >
                  Kup dostęp do kalendarza
                </a>
              </div>
              <p className="text-lg text-white text-shadow">
                Jeśli kupiłaś dostęp, ale nie możesz się dostać do kalendarza,
                wyślij mi maila na adres:{" "}
                <a href="mailto:kontakt@tosiakowo.pl">kontakt@tosiakowo.pl</a>
              </p>
            </ContentBox>
          )}

          {isLoading && (
            <div className="absolute inset-0 w-full h-100vh flex items-center justify-center">
              <img
                src="/loader.png"
                className="w-[315px] h-[400px] animate-wiggle"
                alt="Ładowanie..."
              />
            </div>
          )}

          {fetchError && (
            <ContentBox>
              <p className="text-lg text-white text-shadow">
                Wystąpił błąd podczas ładowania kalendarza :(
              </p>
              <p className="text-lg text-white text-shadow">
                Spróbuj odświeżyć stronę, a jeśli to nie pomoże - wyślij mi
                maila na adres:{" "}
                <a href="mailto:kontakt@tosiakowo.pl">kontakt@tosiakowo.pl</a>
              </p>
            </ContentBox>
          )}

          {isAuthorized && (
            <Content daysData={daysData} onTileClick={onTileClick} />
          )}
        </div>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedDay={selectedDay}
      />
    </>
  );
};

export default App;
